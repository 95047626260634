import React, { FC, ReactNode, useRef } from "react";
import { createPortal } from "react-dom";
import Heading from "./Heading";

export type ModalProps = {
  title: string;
  children?: ReactNode;
  close?: () => void;
  width?: number;
};

export const Modal: FC<ModalProps> = ({ title, children, close, width }) => {
  const shadeRef = useRef<HTMLDivElement | null>(null);

  return createPortal(
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.75)",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: 11,
          left: 0,
          bottom: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          fontSize: "2em",
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (close && e.key === "Escape") {
            close();
          }
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            opacity: 0.5,
          }}
          ref={shadeRef}
          onClick={(e) => {
            if (close && e.target === shadeRef.current) close();
          }}
        />
        <div
          style={{
            backgroundColor: "#121f35",
            border: "solid 4px #fff",
            padding: "1rem",
            position: "relative",
            zIndex: 2,
            boxSizing: "content-box",
            top: "100px",
            width: width ?? "100%",
            maxWidth: "650px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            <Heading style={{ margin: "0" }}>{title}</Heading>
            {close && (
              <button
                onClick={close}
                onKeyPress={(e) => {
                  if (e.key === "Enter") close();
                }}
                style={{
                  marginLeft: "2rem",
                  marginTop: "-10px",
                  border: "1px solid white",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                  fontSize: "20px",
                  fontFamily: "alagard",
                  height: "30px",
                  width: "30px",
                  background: "none",
                  color: "white",
                }}
              >
                x
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </>,
    document.body
  );
};

export default Modal;
