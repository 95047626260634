import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import Text from "./Text";
import BN from "bignumber.js";
import { web3Util } from "./web3util";
import useAudioHook from "./useAudioHook";
// @ts-ignore
import txSubmittedSound from "./assets/tx-submitted.wav";
// @ts-ignore
import completedSound from "./assets/completed.wav";
import Spacer from "./Spacer";

const Input = styled.input`
  background: white;
  padding: 10px;
  width: 100%;
`;

const MintBtn = styled.button`
  font-family: alagard;
  background: #49994e;
  color: white;
  border: none;
  padding: 12px 40px;
  font-size: 3rem;
  margin-bottom: 2rem;
  border-radius: 8px;

  &:disabled {
    opacity: 0.5;
  }
`;

const HeroRow = styled.div`
  display: flex;
  justify-content: center;
  & div {
    width: 150px;
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    & svg {
      width: 100%;
      height: auto;
    }
  }
  flex-wrap: wrap;
`;

function useWatchTx(hash: string, cb: any) {
  const [status, updateStatus] = useState<"pending" | "complete">("pending");
  useEffect(() => {
    if (hash) {
      const onComplete = (completedHash: string) => {
        if (completedHash === hash) {
          updateStatus("complete");
          cb();
        }
      };

      web3Util.pollTx?.on("completed", onComplete);
      web3Util.pollTx?.watch(hash);
    }

    return () => web3Util.pollTx?.removeAllListeners("completed");
  }, [hash]);
  return status;
}

export default function MintModal({ onClose }: { onClose: any }) {
  const [amount, updateAmount] = useState("1");
  const [error, updateError] = useState("");
  const [loading, updateLoading] = useState(false);
  const [hash, updateHash] = useState("");
  const [heroesLoading, updateLoadingHeroes] = useState(true);
  const [heroes, updateHeroes] = useState<string[]>([]);
  const { play: playTxSubmitted } = useAudioHook(txSubmittedSound, false);
  const { play: playCompleted } = useAudioHook(completedSound, false);

  const txStatus = useWatchTx(hash, async () => {
    await playCompleted();
    try {
      const contract = web3Util.getContract();
      const balance = await contract?.methods
        .balanceOf(web3Util.accounts?.[0])
        .call();
      let uris = [];
      for (let i = 0; i < parseInt(balance); i++) {
        const fn = async () => {
          const tokenId = await contract?.methods
            .tokenOfOwnerByIndex(web3Util.accounts?.[0], i)
            .call();
          const svg = await contract?.methods.genSvg(tokenId).call();
          return svg;
        };

        uris.push(fn());
      }
      uris = await Promise.all(uris);
      updateHeroes(uris as string[]);
      updateLoadingHeroes(false);

      console.log(uris);
    } catch {
      // no op
    }
  });
  function submit(e: any) {
    e.preventDefault();
    updateLoading(true);
    const bn = new BN(amount);
    if (bn.isNaN() || bn.lt(1) || bn.gt(10)) {
      updateError("Please enter a number between 1 and 10");
      updateLoading(false);
      return;
    }
    if (error) {
      updateError("");
    }

    const contract = web3Util.getContract();
    contract?.methods
      .mint(amount)
      .send({
        from: web3Util.accounts?.[0],
        value: web3Util.web3?.utils.toWei(bn.times(0.08).toFixed()),
      })
      .on("transactionHash", async (hash: string) => {
        await playTxSubmitted();
        updateHash(hash);
      })
      .on("error", (e: any) => {
        alert(e.message);
      });

    updateLoading(false);
  }

  const pendingTx = txStatus === "pending" && hash;
  const completeTx = txStatus === "complete";

  let body = (
    <>
      <Text>How many? Max 10</Text>
      <Text> 0.08 ETH Each</Text>
      <Input
        style={{ margin: "20px 0" }}
        type="text"
        value={amount}
        onChange={(e) => updateAmount(e.target.value)}
      />
      <MintBtn disabled={loading} onClick={submit}>
        {!loading ? "Submit Transaction" : "Please wait"}
      </MintBtn>
      {error && <Text style={{ color: "red" }}>{error}</Text>}
    </>
  );

  if (pendingTx) {
    body = (
      <Spacer>
        <Text>Transaction submitted</Text>
        <Text>Please wait</Text>
        <Text>
          <a
            href={`https://etherscan.io/tx/${hash}`}
            target="_blank"
            rel="noreferrer"
          >
            View on Etherscan
          </a>
        </Text>
      </Spacer>
    );
  }

  if (completeTx) {
    body = (
      <Spacer>
        <Text>Your Heroes have been summoned!</Text>
        {heroes.length === 0 && <Text>Loading your Heroes...</Text>}
        <HeroRow>
          {heroes.length > 0 &&
            heroes.map((hero: any, i) => {
              return <div key={i} dangerouslySetInnerHTML={{ __html: hero }} />;
            })}
        </HeroRow>
      </Spacer>
    );
  }

  return (
    <Modal title="Mint" close={onClose}>
      {body}
    </Modal>
  );
}
