import React, { useMemo, useRef, useState } from "react";
import logo from "./assets/title1.png";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import bg1 from "./assets/bg_1800x80.png";
import bg2 from "./assets/bg_1800x80_2.png";
import connectBg from "./assets/connect.png";
import scrollDown from "./assets/scroll-down.svg";
import heroRow from "./assets/hero-row.png";
import mintBg from "./assets/mint.png";
import scroll from "./assets/scroll.png";
import map from "./assets/map.png";
import merlin from "./assets/merlin_with_bg.png";
// @ts-ignore
import font from "./assets/alagard.ttf";
// @ts-ignore
import music from "./assets/2018-04-02_-_Beautiful_Village_-_David_Fesliyan.mp3";
import { web3Util } from "./web3util";
import Heading from "./Heading";
import Text from "./Text";
import MintModal from "./MintModal";
import useAudioHook from "./useAudioHook";
import Spacer from "./Spacer";
import heroGif from "./assets/heroes.gif";
import heartPng from "./assets/heart.png";

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: alagard;
    src: url(${font});
  }

  body {
    font-family: alagard;
  }

  html {
    font-size: 10px;
    scroll-behavior: smooth;
    @media (max-width: 600px) {
      font-size: 8px;
    }
  }



  a, a:visited {
    color: #FFF;
  }
`;

const Hero = styled.div`
  height: 100vh;
  position: relative;
  background-image: url(${bg1});
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;

  & .logo {
    width: 100%;
    max-width: 1200px;
    z-index: 1;
  }

  & * {
    z-index: 1;
  }
`;

const Connect = styled.div`
  background-image: url(${connectBg});
  width: 100%;
  max-width: 29.6rem;
  cursor: pointer;
  height: 13rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (max-width: 500px) {
    transform: scale(0.7);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Mint = styled.div`
  background-image: url(${mintBg});
  width: 230px;
  height: 130px;
  cursor: pointer;

  opacity: 0;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  animation: ${fadeIn} 2s forwards;
  @media (max-width: 500px) {
    transform: scale(0.7);
  }
`;

const HeroText = styled.p`
  font-family: alagard;
  font-size: 40px;
  color: #ffffff;
  text-shadow: 0 2px 0 #000;
`;

const Bg = styled.img`
  opacity: ${(props: { connected: boolean }) => (props.connected ? "1" : "0")};
  object-fit: cover;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  transition: opacity 5s;
`;

const More = styled.a`
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  & img {
    width: 30px;
    height: auto;
  }
`;

const HeroRow = styled.div`
  background: linear-gradient(
    to bottom,
    ${(props: { connected: boolean }) =>
      props.connected ? "rgb(152,80,81)" : "#49994e"},
    #121f35
  );
  display: flex;
  justify-content: center;
  transition: all 3s;
`;

const Inner = styled.div`
  max-width: 1200px;
  padding: 10px;
`;

const HeroRowInner = styled(Inner)`
  text-align: center;

  padding: 100px 15px;
  & img {
    max-width: 100%;
  }

  & img:first-child {
    display: none;
  }

  @media (max-width: 396px) {
    padding: 0;
    & img:first-child {
      display: block;
    }
  }
`;

const About = styled.div`
  background: #121f35;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  padding-bottom: 250px;
`;

const Section1 = styled.div`
  display: flex;
  width: 100%;
  max-width: 750px;
  margin: 100px 0;
  justify-content: center;
  align-items: center;
  & img {
    width: 100%;
    max-width: 235px;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    text-align: center;

    & img {
      margin-bottom: 2rem;
    }
  }
`;

const Roadmap = styled.div`
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin-top: 50px;

  & img {
    width: 100%;
    max-width: 120px;
    margin-bottom: 10px;
  }

  & #list div {
    margin-bottom: 5rem;
  }
`;

const Section3 = styled.div`
  & > div {
    width: 50%;
    padding: 10px;
    text-align: center;
  }
  width: 100%;
  max-width: 1200px;
  display: flex;

  margin-top: 10rem;

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    & > div {
      width: 100%;
      &:last-child {
        margin-top: 50px;
      }
    }
  }
`;

const Section4 = styled.div`
  width: 100%;
  max-width: 780px;
  margin: auto;
  text-align: center;
  margin-top: 200px;
`;

const MerlinImg = styled.div`
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  max-width: 320px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  & img {
    width: 100%;
    height: auto;
  }
`;

function App() {
  const { playing, play, pause } = useAudioHook(music, true);
  const [connected, updateShowConnected] = useState(false);
  const [showMintModal, updateShowMintModal] = useState(false);
  const playMusic = async () => {
    if (!playing) {
      await play();
    }
    updateShowConnected(true);
  };

  return (
    <>
      <GlobalStyle />
      {showMintModal && (
        <MintModal onClose={() => updateShowMintModal(false)} />
      )}
      <Hero>
        <img className="logo" src={logo} />

        <div>
          <HeroText
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              textAlign: "center",
            }}
          >
            <div>All Heroes have been summoned.</div>
            <div>Links:</div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://market.theheroes.app"
                onClick={playMusic}
              >
                Official Heroes Marketplace and Staking{" "}
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://market.theheroes.app/dungeons"
                onClick={playMusic}
              >
                Dungeons mint
              </a>
            </div>
            <div>
              <a
                href="https://opensea.io/collection/the-heroes-nft-collection"
                target="_blank"
                rel="noreferrer"
              >
                View on OpenSea
              </a>
            </div>
            <div>
              <a href="#" onClick={playMusic}>
                Play Music
              </a>
            </div>
          </HeroText>
        </div>
        <More href="#more">
          <HeroText>More</HeroText>
          <img src={scrollDown} />
        </More>
        <Bg src={bg2} connected={connected} />
      </Hero>
      <div id="more">
        <HeroRow connected={connected}>
          <HeroRowInner>
            <img src={heroGif} />
            <img src={heroRow} />
          </HeroRowInner>
        </HeroRow>
        <About>
          <Section1>
            <div>
              <Heading>A prophecy foretold...</Heading>
              <Text>
                3,333 heroes would come forth to fight for the soul of humankind
                against an abhorrent evil.
              </Text>
            </div>
            <img src={scroll} />
          </Section1>
          <Roadmap>
            <img src={map} />
            <Heading style={{ marginBottom: "50px" }}>Roadmap</Heading>
            <div id="list">
              <Text>Heroes are summoned</Text>
              <Text>All Hero art is made available for easy downloading</Text>
              <Text>Game sprites are made for each hero</Text>

              <Text>Hero Marketplace</Text>
              <Text style={{ color: "#CEFF05" }}>Ghouls rise</Text>
              <Text>Hero Universe</Text>
            </div>
          </Roadmap>
          <Section3>
            <div>
              <Heading>Forever on-chain</Heading>
              <Text>
                Heroes are dynamically created on-chain. Traits and stats are
                stored in the smart contract and can be accessed by other smart
                contracts. The on-chain data can be used to create anything you
                can imagine.
              </Text>
            </div>
            <div>
              <Heading>CC0 art</Heading>
              <Text>
                Hero art falls under the CC0 license, which means you can do
                anything you'd like with your heroes.
              </Text>
            </div>
          </Section3>
          <Section4>
            <Heading>Merlin the Blue</Heading>
            <Text>
              A most powerful wizard, Merlin has come to tell the world of the
              foretold prophecy and spread the news of the heroes.
            </Text>
            <MerlinImg>
              <img src={merlin} />
            </MerlinImg>
            <Spacer>
              <Text>Follow Merlin for updates</Text>

              <Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/MerlinTheBlue1"
                >
                  Twitter
                </a>
              </Text>
              <Text>
                <a
                  href="https://discord.gg/7sE2se2Z62"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </Text>
              <Text>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://opensea.io/collection/the-heroes-nft-collection"
                >
                  OpenSea
                </a>
              </Text>
              <Text>
                <a
                  href="https://etherscan.io/address/0x34BB16FCd733F8EE46D48206f7154f7cD585F97a"
                  target="_blank"
                  rel="noreferrer"
                >
                  Etherscan
                </a>
              </Text>
            </Spacer>
          </Section4>
        </About>
      </div>
    </>
  );
}

export default App;
